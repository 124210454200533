interface ThemeShape {
  breakpoints: string[]
  backgrounds: string[]
  fontSizes: string[]
  colors: {
    [key: string]: string
  }
  space: string[]
  fontWeights: {
    [key: string]: number
  }
  sidebarWidth: {
    [key: string]: string
  }
}

const theme: ThemeShape = {
  breakpoints: ['480px', '650px', '1000px', '1200px', '1300px'],
  backgrounds: [`url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2311589f' fill-opacity='0.21' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`],
  fontSizes: ['0.89rem', '1.1rem', '1.34rem', '1.528rem', '1.874rem', '1.988rem'],
  colors: {
    primary: '#11589F',
    secondary: '#F2DAE2',
    grey: '#58545a',
    store: '#e2e2e2',
    shade: '#f5f5f5',
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  fontWeights: {
    normal: '300',
    bold: '500',
  },
  sidebarWidth: {
    big: '375px',
    normal: '320px',
  },
}

export default theme
