import React from 'react'

const Logo = () => (
  <svg viewBox="0 0 495.6 495.6">
    <title>ASC Studio, Dublin</title>
    <path d="M495.6,247.8C495.6,110.9,384.7,0,247.8,0S0,110.9,0,247.8c0,47.4,13.3,91.7,36.4,129.3h0
    	c43.6,71.1,122,118.5,211.4,118.5c89.5,0,167.8-47.4,211.4-118.5h0C482.3,339.5,495.6,295.2,495.6,247.8z M404.6,200.4
    	c-25.3,0-44.4,16.5-48,42c0.9-29.9,21.1-49.8,48.9-49.8c16,0,28,5.3,37.4,12.4v8.5C433.4,206,421.2,200.4,404.6,200.4z M250.9,200
    	c-12.6,0-21.1,5.7-23.5,14.2c0-0.6-0.1-1.2-0.1-1.8c0-11.8,9.4-20.1,24.9-20.1c8.6,0,19.5,4,27.5,9.6l-1.9,7.5
    	C270,203.9,259.3,200,250.9,200z M216.3,214.5c0.9,15.3,12,26.8,31.4,32.2c22.7,6.2,29.3,14.3,29.3,27.7c0,3.7-0.8,7-2.2,9.9
    	c0.2-1.3,0.4-2.7,0.4-4.2c0-13-6.4-21-28.6-27c-19.8-5.4-30.7-17.5-30.7-33.4C215.8,217.9,216,216.2,216.3,214.5z M128.5,266.7
    	l-19.6-48.2l4.1-10.1l23.7,58.3H128.5z M101.1,193h7.6L61,309.6h-8.1L101.1,193z M132.7,276.4h7.9l13.6,33.2h-7.7L132.7,276.4z
    	 M249,312.1c-16.9,0-29.9-6.8-36.3-13l0.9-4.7c6.7,6.2,19.8,12.8,36.6,12.8c13.8,0,28.1-6.5,34.5-19.2
    	C280.2,304,264.3,312.1,249,312.1z M404.6,312.1c-32.4,0-59.7-23.1-59.7-60.8c0-3.1,0.2-6.1,0.6-9c0,0.7,0,1.3,0,2
    	c0,38.3,27.4,61.9,60.1,61.9c13.3,0,27.9-4.4,37.4-11v5.3C433.6,307.5,418.4,312.1,404.6,312.1z"/>
    </svg>
)

export default Logo
